import React, { useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent, Container, IconButton } from "@mui/material";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "../styles/styles.css";
import "../styles/tokens.css";

export default function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleScrollDown = () => {
    const missionSection = document.getElementById('mission-section');
    if (missionSection) {
      missionSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const features = [
    {
      title: "Collaborative Intelligence",
      description: "Foster an environment where collective thinking creates exponential results.",
      icon: "/images/brilliant.png",
    },
    {
      title: "Innovation Catalyst",
      description: "Transform ideas into groundbreaking solutions through synergistic approaches.",
      icon: "/images/innovator.png",
    },
    {
      title: "Growth Mindset",
      description: "Cultivate continuous learning and development at both individual and team levels.",
      icon: "/images/growth.png",
    },
    {
      title: "Deep Reflection",
      description: "Engage in meaningful dialogue and thoughtful analysis of challenges.",
      icon: "/images/deep_reflection.jpeg",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      {/* Hero Section */}
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          background: "linear-gradient(135deg, var(--primary-color) 0%, var(--accent-color) 100%)",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {/* Animated Background Elements */}
        <motion.div
          animate={{
            rotate: 360,
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
          style={{
            position: "absolute",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.1)",
            filter: "blur(60px)",
          }}
        />

        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "4rem" },
                    fontWeight: "bold",
                    marginBottom: 2,
                    color: "white",
                  }}
                >
                  Synergistic Intelligence
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "1.2rem", md: "1.8rem" },
                    opacity: 0.9,
                    marginBottom: 4,
                    color: "white",
                  }}
                >
                  Where Innovation Meets Collaboration
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, delay: 0.3 }}
              >
                <Box
                  component="img"
                  src={process.env.PUBLIC_URL + "/images/Frame.svg"}
                  alt="Hero"
                  sx={{
                    width: "100%",
                    maxWidth: "500px",
                    filter: "drop-shadow(0 0 20px rgba(255,255,255,0.3))",
                  }}
                />
              </motion.div>
            </Grid>
          </Grid>

          {/* Scroll Indicator */}
          <motion.div
            animate={{
              y: [0, 10, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
            style={{
              position: "absolute",
              bottom: "2rem",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 2,
            }}
          >
            <IconButton
              onClick={handleScrollDown}
              sx={{
                color: "white",
                border: "2px solid white",
                "&:hover": {
                  background: "rgba(255,255,255,0.1)",
                },
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </motion.div>
        </Container>
      </Box>

      {/* Mission Statement */}
      <Box
        sx={{
          background: "linear-gradient(135deg, var(--primary-color) 0%, var(--accent-color) 100%)",
          color: "white",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container id="mission-section" maxWidth="lg" sx={{ py: 12 }}>
          <Box
            data-aos="fade-up"
            sx={{
              textAlign: "center",
              maxWidth: "800px",
              margin: "0 auto",
              position: "relative",
            }}
          >
            <Typography
              variant="h3"
              className="pop"
              sx={{ marginBottom: 4, position: "relative", color: "white" }}
            >
              Our Mission
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100px",
                  height: "4px",
                  background: "white",
                  borderRadius: "2px",
                }}
              />
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                color: "white",
                opacity: 0.9,
              }}
            >
              At Synergistic Intelligence, we specialize in fostering an
              environment "where the whole is greater than sum of its parts." Drawing
              inspiration from the groundbreaking concepts in "The Fifth
              Discipline" by Peter Senge and David Bohm's "On Dialogue," we have
              developed a unique approach that leverages the emergent property of
              group intelligence.
            </Typography>
          </Box>
        </Container>

        {/* Background Animation */}
        <Box
          component={motion.div}
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.1)",
            filter: "blur(60px)",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        />
      </Box>

      {/* Features Grid */}
      <Box
        id="features-section"
        sx={{
          background: "linear-gradient(135deg, var(--primary-color) 0%, var(--accent-color) 100%)",
          color: "white",
          py: 12,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <Grid container spacing={4}>
              {features.map((feature, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <motion.div variants={itemVariants}>
                    <Card
                      sx={{
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.1)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "20px",
                        transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-10px)",
                          boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
                        },
                      }}
                    >
                      <CardContent sx={{ p: 4, textAlign: "center" }}>
                        <Box
                          component="img"
                          src={process.env.PUBLIC_URL + feature.icon}
                          alt={feature.title}
                          sx={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            marginBottom: 2,
                          }}
                        />
                        <Typography
                          variant="h5"
                          className="pop"
                          sx={{ marginBottom: 2, color: "white" }}
                        >
                          {feature.title}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "rgba(255, 255, 255, 0.9)" }}>
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Container>

        {/* Background decorative elements */}
        <Box
          component="img"
          src={process.env.PUBLIC_URL + "/images/Decor.png"}
          alt="Decoration"
          sx={{
            position: "absolute",
            top: "10%",
            right: "-5%",
            opacity: 0.1,
            transform: "rotate(45deg)",
            width: "300px",
          }}
        />

        {/* Background Animation */}
        <Box
          component={motion.div}
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.1)",
            filter: "blur(60px)",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        />
      </Box>

      {/* Innovation Section */}
      <Box
        id="innovation-section"
        sx={{
          background: "linear-gradient(135deg, var(--primary-color) 0%, var(--accent-color) 100%)",
          color: "white",
          py: 12,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box data-aos="fade-right">
                <Typography
                  variant="h3"
                  className="pop"
                  sx={{ marginBottom: 4, color: "white" }}
                >
                  Holistic, Transcendent Innovation
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: 1.8,
                    color: "white",
                    opacity: 0.9,
                  }}
                >
                  Our methodology is centered on creating an atmosphere of open
                  communication, shared understanding, and mutual respect, crucial for
                  nurturing what we call 'Synergistic Intelligence.' This is not just
                  about pooling individual knowledge; it's about creating something
                  greater – a collective cognitive capability that transcends
                  individual capacities.
                  <br />
                  <br />
                  Imagine your team not just sharing ideas, but collectively evolving
                  them. With our guidance, teams learn to think together in a
                  coordinated and aligned manner, tapping into a holistic and
                  transcendent form of innovation.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                data-aos="fade-left"
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "30px",
                  },
                }}
              >
                <Box
                  component="img"
                  src={process.env.PUBLIC_URL + "/images/flow_ident.jpeg"}
                  alt="Innovation"
                  sx={{
                    width: "100%",
                    borderRadius: "30px",
                    transform: "scale(0.95)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1)",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Background Animation */}
        <Box
          component={motion.div}
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.1)",
            filter: "blur(60px)",
            transform: "translate(-50%, -50%)",
            zIndex: 0,
          }}
        />
      </Box>

      {/* Call to Action */}
      <Box
        id="cta-section"
        sx={{
          background: "linear-gradient(135deg, var(--primary-color) 0%, var(--accent-color) 100%)",
          color: "white",
          py: 12,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Box
            data-aos="zoom-in"
            sx={{
              textAlign: "center",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                marginBottom: 4,
                fontWeight: "bold",
                color: "white",
              }}
            >
              Ready to Transform Your Team?
            </Typography>
            <Typography
              variant="h5"
              sx={{
                marginBottom: 4,
                opacity: 0.9,
                maxWidth: "800px",
                margin: "0 auto",
                color: "white",
              }}
            >
              Let's embark on a journey to transform your team's collaborative
              potential into holistic, transcendent innovation. Together, we'll
              redefine what's possible.
            </Typography>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ marginTop: "60px" }}
            >
              <Box
                component="button"
                sx={{
                  padding: "16px 32px",
                  fontSize: "1.2rem",
                  borderRadius: "30px",
                  background: "white",
                  color: "var(--primary-color)",
                  border: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                  },
                }}
              >
                Get Started Today
              </Box>
            </motion.div>
          </Box>
        </Container>

        {/* Animated background elements */}
        <Box
          component={motion.div}
          animate={{
            rotate: [0, 360],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear",
          }}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background: "rgba(255, 255, 255, 0.1)",
            filter: "blur(60px)",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    </Box>
  );
}
